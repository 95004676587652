import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Button,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import Layout from "../../Layout";
import Paginator from "../../Paginator";
const endpoint = "dekabot";

const Dekabot = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);


  const goEditProduct = (product) => {
    navigate("/form-dekabot", { state: { product, isEdit: true } }); 
  };
  
  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `${product.pdf_no} nolu pdfi silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id };
          const result = await formApi.deletePage(obj);
          if (result) {
            alertify.success("PDF silindi..", 2.5);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        } catch (e) {
          alertify.error("PDF silinemedi..", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center" key={product.pdf_no}>
        <td>{product.pdf_no}</td>

        <a className="p-0 m-0"
          href={`https://server.dekasolar.com/uploads/${product.pdf}`} 
          target="_blank"
          rel="noopener noreferrer"
        >
          {product.pdf}
        </a>
        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Ekli Pdfler</b>
                  </CardTitle>
                  <br />

                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3">
                    <Button
  className="bg-black"
  onClick={() => navigate("/form-dekabot", { state: { isEdit: false } })} 
>
  PDF Ekle
</Button>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                                <th>PDF No</th>

                                <th>Yüklü PDF</th>
                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">
                                  PDF bulunamadı
                                </p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator
                              len={products.length}
                              data={products}
                              setTempProducts={setTempProducts}
                              loadPage={loadPage}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Dekabot;
