import React from "react";
import { Container } from "reactstrap";
import Layout from "../../Layout";
import Footer from "../../Layout/Footer";
import Arac from "./Arac";

const AracMain = () => {
  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Araç Sarj İstasyonları</div>
        </Container>
        <Arac />
        <Footer />
      </main>
    </Layout>
  );
};

export default AracMain;
