import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "denetim";

function FormIndexDenetim() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
  
    text: ""
  });

  const [validation, setValidation] = useState({
   text:""
  });
  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState }));
        });
      }
      setIsEdit(
        location.state.isEdit !== undefined ? location.state.isEdit : false
      );
    }
  }, []);

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

 
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("text", form.text);
      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/denetim"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Denetim ve Sertifikasyon</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>İçerik Düzenleme Paneli</h4>
                    <hr />

                    <Form >
                      <br />
                     

                      <Label for="text">
                        <b>İçerik</b>
                      </Label>
                      <CKEditor
                        name="text"
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          if (form.text !== "") editor.setData(form.text);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("text", editor.getData())
                        }
                      />
                      {validation.text === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />
                   
                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                          display: isEdit ? "inline-block" : "none",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexDenetim;
