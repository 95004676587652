import React from "react";
import {
  Container,

} from "reactstrap";
import Layout from "../../Layout";
import Footer from "../../Layout/Footer";
import Fizibilite from "./Fizibilite";

const FizibiliteMain = () => {
  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Fizibilite Raporu Hazırlanması</div>
        </Container>

        <Fizibilite />
        <Footer />
      </main>
    </Layout>
  );
};

export default FizibiliteMain;
