import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Button, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";
const endpoint = "experience";


const Experience = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [search, setSearch] = useState("");

  const goEditProduct = (product) => {
    navigate("/form-deneyimler",{ state: { product, isEdit: true } });
  };

  const deleteProduct = async (e, product) => {
    e.preventDefault();

    alertify.confirm(
      "Silme İşlemi",
      `${product.exp_no} nolu deneyimi silmek istediğinizden emin misiniz?`,
      async function () {
        try {
          const obj = { _id: product._id, img: product.img };
          const result = await formApi.deleteImage(obj);
          if (result) {
            alertify.success("Deneyim silindi.", 2.5);
            setProducts(products.filter(item => item._id !== product._id));
            setTempProducts(tempProducts.filter(item => item._id !== product._id));
            setTimeout(() => {
              window.location.reload();
            }, 1000); 
          }
        } catch (e) {
          alertify.error("Deneyim silinemedi.", 2.5);
        }
      },
      function () {
        alertify.error("İşlemden vazgeçildi...", 20);
      }
    );
  };

  const loadPage = (products) => {
    return products.map((product) => (
      <tr className="align-middle text-center" key={product.exp_no}>
        <td>{product.exp_no}</td>
        <td className="">
          <img src={`https://server.dekasolar.com/${product.img}`} alt={product.title} height={50} />
        </td>
        <td>{product.title}</td>
        <td>{product.title_inner}</td>
        <td>{product.title_inner_2}</td>
        <td>   {product.desc_seo
            ? ReactHtmlParser(product.desc_seo)
            : "İçerik bulunamadı."}</td>
     
        <td>
        {/* Projeleri göstermek için bir tablo */}
        <Table bordered striped>
          <thead>
            <tr>
              <th>Konum</th>
              <th>Tür</th>
            </tr>
          </thead>
          <tbody>
            {product.projects.map((project, index) => (
              <tr key={index}>
                <td>{project.location}</td>
                <td>{project.type}</td>
                <td className="d-flex"><img  style={{height:"7vh"}} src={`https://server.dekasolar.com/uploads/${project.project_img}`}></img></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </td>
       

        <td className="option">
          <BiEdit onClick={() => goEditProduct(product)} />
          <AiFillDelete onClick={(e) => deleteProduct(e, product)} />
        </td>
      </tr>
    ));
  };
  

  const onSearchHandler = (e) => {
    e.preventDefault();

    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(search.toLowerCase())
    );

    setTempProducts(loadPage(filteredProducts));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Deneyimler</b>
                  </CardTitle>
                  <br />
                  <div className="p-3">
                    <div className="d-lg-flex d-md-flex  justify-content-between p-3">
                      <Button className="bg-black" onClick={() => navigate("/form-deneyimler")}>
                        Deneyim Ekle
                      </Button>
                      <form className="d-flex" onSubmit={onSearchHandler}>
                        <Input
                          type="search"
                          name="search"
                          id="exampleSearch"
                          placeholder="Başlığa göre arayın..."
                          value={search}
                          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                          className="bg-black"
                          type="submit"
                          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        >
                          Ara
                        </Button>
                      </form>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                                <th>Deneyim No</th>
                                <th>Görsel</th>
                                <th>Deneyim Adı</th>
                                <th>İç Başlık 1</th>
                                <th>İç Başlık 2</th>
                                <th>Deneyim Açıklaması</th>
                            <th>Proje bilgileri</th>
                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <tr>
                                  <td colSpan="10" className="text-danger text-center p-2">
                                    Deneyim bulunamadı.
                                  </td>
                                </tr>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator
                              len={products.length}
                              data={products}
                              setTempProducts={setTempProducts}
                              loadPage={loadPage}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Experience;
