import React from "react";
import {
  Container,

} from "reactstrap";
import Layout from "../../Layout";
import Footer from "../../Layout/Footer";
import Isveren from "./Isveren";

const IsverenMain = () => {
  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">İşveren Mühendisliği</div>
        </Container>

        <Isveren />
        <Footer />
      </main>
    </Layout>
  );
};

export default IsverenMain;
