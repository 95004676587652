import React from "react";
import {
  Container,

} from "reactstrap";
import Layout from "../../Layout";
import Footer from "../../Layout/Footer";
import Denetim from "./Denetim";

const DenetimMain = () => {
  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Denetim ve Sertifikasyon</div>
        </Container>

        <Denetim />
        <Footer />
      </main>
    </Layout>
  );
};

export default DenetimMain;
