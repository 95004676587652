import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "../../Slider";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const endpoint = "gesDenetimleri";
function FormIndexGesDenetimleri() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
    ges_no: "",
    desc: "",
    title: "",
    img:""
 
  });

  const [validation, setValidation] = useState({
    ges_no: "",
   desc: "",
    title: "",
    img:""

  });
  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState }));
        });
      }
      setIsEdit(location.state.isEdit !== undefined ? location.state.isEdit : false);
    }
  }, []);
  
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("desc", form.desc);
    formData.append("ges_no", form.ges_no);
    formData.append("title", form.title);
    formData.append("img", form.img);

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Ges denetimleri yazısı eklendi..",
        ((window.location.href = "/form-ges-denetimleri"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Ges denetimleri yazısı eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("desc", form.desc);
    formData.append("ges_no", form.ges_no);
    formData.append("title", form.title);
    formData.append("img", form.img);
      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/ges-denetimleri"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, img: "T" }));
      });
  }, []);

  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };

  return (
    <Layout>
        <ToastContainer/>
      <main className="home">
        <Container>
          <div className="h1">Ges Denetimleri</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                 
                  <br />
                  <div className="container mt-3 pb-5">
      <h4>Ges denetimleri yazısı ekleme Paneli</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        

      
      <Label>
          <b>Fotoğraflar(Fotoğraf boyutu 430x230 boyutlarında olmalıdır! İlgili görseli seçtikten sonra mavi yükleme butonuna tıklayınız.)</b>
        </Label>

        <Slider imageUpload={imageUpload} data={dataSources} />

      
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="ges_no">
                <b>Ges denetimleri yazısı Numarası</b>
              </Label>
              <Input
                id="ges_no"
                name="ges_no"
                placeholder="Lütfen Ges denetimleri yazısı numarası giriniz..."
                type="number"
                valid={validation.ges_no === "T"}
                invalid={validation.ges_no === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.ges_no}
                min={0}
              />
         
            </FormGroup>
         
            <FormGroup>
              <Label for="title">
                <b>Ges denetimleri yazısı adı</b>
              </Label>
              <Input
                id="title"
                name="title"
                placeholder="Lütfen Ges denetimleri yazısı adını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.title}
                valid={validation.title === "T"}
                invalid={validation.title === "F"}
              />
           
            </FormGroup>
            <Label for="desc">
          <b>Açıklama(tr)</b>
        </Label>
        <CKEditor
          name="desc"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.desc !== "") editor.setData(form.desc);
          }}
          onChange={(event, editor) =>
            onChangeHandler("desc", editor.getData())
          }
        />
        {validation.icerik === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}

        <br />
          </div>
        </div>

       
        {validation.icerik === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}

        <br />
        <Button
  color="dark"
  type="submit"
  onClick={(e) => onSubmitHandler(e)}
  style={{ display: isEdit ? "none" : "inline-block" }}
>
  Ekle
</Button>
{"  "}
<Button
  type="submit"
  style={{
    background: "white",
    color: "black",
    display: isEdit ? "inline-block" : "none",
  }}
  onClick={(e) => onUpdateHandler(e)}
>
  Güncelle
</Button>
      </Form>
    </div>
                
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexGesDenetimleri;
