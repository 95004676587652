import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardBody, Row } from "reactstrap";
import Layout from "../../Layout";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import alertify from "alertifyjs";

const endpoint = "dekabot";

function FormIndexDekabot() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
    pdf_no: "",
    pdf: "",
  });

  const [validation, setValidation] = useState({
    pdf_no: "",
    pdf: "",
  });
  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState }));
        });
      }
      setIsEdit(
        location.state.isEdit !== undefined ? location.state.isEdit : false
      );
    }
  }, []);

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("pdf_no", form.pdf_no);
    formData.append("pdf", form.pdf);

    try {
      const rst = await formApi.addPage(formData);
      alertify.success(
        "PDF eklendi..",
        ((window.location.href = "/dekabot"), 1000),
        2.5
      );
    } catch (e) {
      alertify.error("PDF eklenemedi..", 1000);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      alertify.error("Düzenlemeler yapılamadı..", 1000);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("pdf_no", form.pdf_no);
      formData.append("pdf", form.pdf);

      try {
        const rst = await formApi.updatePage(formData);
        alertify.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/dekabot"), 1000),
          2.5
        );
      } catch (e) {
        alertify.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  const pdfUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["pdf"]: data }));
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">PDF Ekleme</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>PDF Ekleme Paneli</h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <br />
                      <div className="row col-12">
                        <div className="col-12">
                          <FormGroup>
                            <Label for="pdf_no">
                              <b>PDF Numarası</b>
                            </Label>
                            <Input
                              id="pdf_no"
                              name="pdf_no"
                              placeholder="Lütfen pdf numarasını giriniz..."
                              type="number"
                              valid={validation.pdf_no === "T"}
                              invalid={validation.pdf_no === "F"}
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.pdf_no}
                              min={0}
                            />
                          </FormGroup>
                          <Label>
                            <b>PDF dosyanızı seçin.</b>
                          </Label>
                          <br/>
                          <input
                            type="file"
                            accept="pdf/*"
                            onChange={(e) => pdfUpload(e.target.files[0])}
                          />
                          <br />
                        </div>
                      </div>
<br/>
                      <Button
                        color="dark"
                        type="submit"
                        onClick={(e) => onSubmitHandler(e)}
                        style={{ display: isEdit ? "none" : "inline-block" }}
                      >
                        Ekle
                      </Button>
                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                          display: isEdit ? "inline-block" : "none", // isEdit flag'ine göre butonu göster
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexDekabot;
