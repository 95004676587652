import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "arac";

function FormIndexArac() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    title: "",
    desc: "",
  });

  const [validation, setValidation] = useState({
    title: "",
    desc: "",
  });
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState }));
      });
  }, []);
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("title", form.title);
      formData.append("desc", form.desc);
      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/arac-sarj-istasyonlari"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">İletişim</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>Araç Sarj İstasyonları Sayfası Düzenleme Paneli</h4>
                    <hr />

                    <Form>
                      <br />
                      <div className="row col-12">
                        <div className="col-12">
                          <FormGroup>
                            <Label for="title">
                              <b>Başlık</b>
                            </Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="Lütfen içeriği giriniz..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.title}
                              valid={validation.title === "T"}
                              invalid={validation.title === "F"}
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <Label for="desc">
                        <b>İçerik</b>
                      </Label>
                      <CKEditor
                        name="desc"
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          if (form.desc !== "") editor.setData(form.desc);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("desc", editor.getData())
                        }
                      />
                      {validation.icerik === "F" && (
                        <div className="val-message">
                          Bu alan boş bırakılamaz.
                        </div>
                      )}

                      <br />

                      {"  "}
                      <Button
                        type="submit"
                        style={{
                          background: "white",
                          color: "black",
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                        Güncelle
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexArac;
