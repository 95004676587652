import { useState } from "react";
import { RMIUploader } from "react-multiple-image-uploader";

function Slider(props) {
  const [visible, setVisible] = useState(false);
  const handleSetVisible = () => {
    setVisible(!visible);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    console.log("Upload files", data);
    props.imageUpload(data[0].file,props.index);
    
  };
  const onSelect = (data) => {
    console.log("Select files", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };

  return (
    <RMIUploader
      isOpen={visible}
      hideModal={hideModal}
      onSelect={onSelect}
      onUpload={onUpload}
      onRemove={onRemove}
      dataSources={props.dataSources}
    />
  );
}

export default Slider;
