import React from "react";
import { Container } from "reactstrap";
import Layout from "../../Layout";
import Footer from "../../Layout/Footer";
import Contact from "./Contact";

const ContactMain = () => {
  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">İletişim</div>
        </Container>
        <Contact />
        <Footer />
      </main>
    </Layout>
  );
};

export default ContactMain;
