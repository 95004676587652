import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import Login from "../pages/Login/Login";

import HomeMain from "../pages/Home/HomeMain";

import ReferencesMain from "../pages/Faq/FaqMain";
import DevamEdenProjelerMain from "../pages/GesDenetimleri/GesDenetimleriMain";
import FormIndexDevamEden from "../pages/GesDenetimleri/FormIndexGesDenetimleri";
import GesDenetimleriMain from "../pages/GesDenetimleri/GesDenetimleriMain";
import FormIndexGesDenetimleri from "../pages/GesDenetimleri/FormIndexGesDenetimleri";
import FaqMain from "../pages/Faq/FaqMain";
import FormIndexFaq from "../pages/Faq/FormIndexFaq";
import FormIndexServices from "../pages/Services/FormIndexServices";
import ServicesMain from "../pages/Services/ServicesMain";
import FormIndexBlog from "../pages/Blog/FormIndexBlog";
import BlogMain from "../pages/Blog/BlogMain";
import FormIndexExperience from "../pages/Experience/FormIndexExperience";
import ExperienceMain from "../pages/Experience/ExperienceMain";
import DekabotMain from "../pages/Dekabot/DekabotMain";
import FormIndexDekabot from "../pages/Dekabot/FormIndexDekabot";
import FormIndexHomepage from "../pages/Homepage/FormIndexHomepage";
import HomepageMain from "../pages/Homepage/HomepageMain";
import ContactMain from "../pages/Contact/ContactMain"
import FormIndexContact from "../pages/Contact/FormIndexContact";
import FormIndexIsveren from "../pages/Isveren/FormIndexIsveren";
import IsverenMain from "../pages/Isveren/IsverenMain";
import DenetimMain from "../pages/Denetim/DenetimMain";
import FormIndexDenetim from "../pages/Denetim/FormIndexDenetim";
import FormIndexArac from "../pages/Arac/FormIndexArac";
import AracMain from "../pages/Arac/AracMain";
import FormIndexFizibilite from "../pages/Fizibilite/FormIndexFizibilite";
import FizibiliteMain from "../pages/Fizibilite/FizibiliteMain";
import FormIndexSayac from "../pages/Sayac/FormIndexSayac";
import SayacMain from "../pages/Sayac/SayacMain";
// const NotFound = lazy(() => import("../pages/NotFound"));

function Routes() {
  const routeConfig = [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/home",
      component: HomeMain,
    },
  
    // {
    //   path: "not-found",
    //   component: NotFound,
    // },
 
    {
      path:"ges-denetimleri",
      component: GesDenetimleriMain
    },
    {
      path:"form-ges-denetimleri",
      component: FormIndexGesDenetimleri
    }
    , {
      path:"faq",
      component: FaqMain
    },
    {
      path:"form-faq",
      component: FormIndexFaq
    }, 
    {
      path:"contact",
      component: ContactMain
    },
    {
      path:"form-contact",
      component: FormIndexContact
    }, {
      path:"homepage",
      component: HomepageMain
    },
    {
      path:"form-homepage",
      component: FormIndexHomepage
    },
    {
      path:"form-hizmetler",
      component: FormIndexServices
    },
    {
      path:"hizmetler",
      component: ServicesMain
    },
    {
      path:"form-blog",
      component: FormIndexBlog
    },
    {
      path:"blog",
      component: BlogMain
    },
    {
      path:"form-dekabot",
      component: FormIndexDekabot
    },
    {
      path:"dekabot",
      component: DekabotMain
    },
    {
      path:"form-deneyimler",
      component: FormIndexExperience
    },
    {
      path:"deneyimler",
      component: ExperienceMain
    },
    {
      path:"form-isveren-muhendisligi",
      component: FormIndexIsveren
    },
    {
      path:"isveren-muhendisligi",
      component: IsverenMain
    },
    {
      path:"form-denetim",
      component: FormIndexDenetim
    },
    {
      path:"denetim",
      component: DenetimMain
    },
    {
      path:"form-arac-sarj-istasyonlari",
      component: FormIndexArac
    },
    {
      path:"arac-sarj-istasyonlari",
      component: AracMain
    },
    {
      path:"form-fizibilite",
      component: FormIndexFizibilite
    },
    {
      path:"fizibilite",
      component: FizibiliteMain
    },
    {
      path:"form-sayac",
      component: FormIndexSayac
    },
    {
      path:"sayac",
      component: SayacMain
    }
  ];
  return (
  <Switcher>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          element={
            <Suspense fallback={<Loader />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
    
    </Switcher>

  );
}

export default Routes;
