import React, { useState, useEffect } from "react";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";
import Paginator from "../../Paginator";
const endpoint = "sayac";

const Sayac = () => {
  const formApi = new FormApi(endpoint);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);

  const goEditProduct = (product) => {
    navigate("/form-sayac", { state: { product, isEdit: true } });
  };
  const texts = [
    "güneş santrali (mw)",
    "türkiyenin her ilinde (il)",
    "sarj istasyon ağı (soket)"
  ];
  
  const loadPage = (products) => {
    return products.map((product,index) => (
      <tr className="align-middle text-center" key={product._id}>
      <td>{texts[index % texts.length]}</td>

        <td className="align-middle">
          {product.text
            ? ReactHtmlParser(product.text.substring(0, 250))
            : "İçerik bulunamadı."}
        </td>

        <td className="option">
          <BiEdit onClick={(e) => goEditProduct(product)} />
        </td>
      </tr>
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await formApi.getPage();
      setProducts(res);
      setTempProducts(loadPage(res));
      setProductsAll(res);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Anasayfa Sayaç</b>
                  </CardTitle>
                  <br />

                  <div className="p-3">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="table-scroll col-12">
                          <Table striped bordered hover>
                            <thead>
                              <tr className="text-bold text-center">
                                <th>Başlık</th>
                                <th>İçerik</th>

                                <th>Seçenekler</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempProducts.length === 0 ? (
                                <p className="text-danger p-2">
                                  İçerik bulunamadı
                                </p>
                              ) : (
                                tempProducts.slice(0, 10)
                              )}
                            </tbody>
                          </Table>
                          {products ? (
                            <Paginator
                              len={products.length}
                              data={products}
                              setTempProducts={setTempProducts}
                              loadPage={loadPage}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                 
                </CardBody>
              </Card>
              
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default Sayac;
