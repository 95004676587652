import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row, Table } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "blog";

function FormIndexBlog() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
    blog_no: "",
    title: "",
    desc: "", 
    pdf:""
  });

  const [validation, setValidation] = useState({
    blog_no: "",
    title: "",
    desc: "",
    pdf:""
  });
  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState }));
        });
      }
      setIsEdit(location.state.isEdit !== undefined ? location.state.isEdit : false);
    }
  }, []);
  
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("title", form.title);
    formData.append("blog_no", form.blog_no);
    formData.append("desc", form.desc);
    formData.append("pdf", form.pdf);

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Blog eklendi..",
        ((window.location.href = "/blog"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Blog eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("title", form.title);
      formData.append("blog_no", form.blog_no);
      formData.append("desc", form.desc);
      formData.append("pdf", form.pdf);
      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/blog"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };


  return (
    <Layout>
        <ToastContainer/>
      <main className="home">
        <Container>
          <div className="h1">Blog Ekleme</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                 
                  <br />
                  <div className="container mt-3 pb-5">
      <h4>Blog Ekleme Paneli</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        

      

        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="blog_no">
                <b>Blog Numarası</b>
              </Label>
              <Input
                id="blog_no"
                name="blog_no"
                placeholder="Lütfen Blog numarasını giriniz..."
                type="number"
                valid={validation.blog_no === "T"}
                invalid={validation.blog_no === "F"}
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.blog_no}
                min={0}
              />
         
            </FormGroup>
            <FormGroup>
              <Label for="title">
                <b>Blog</b>
              </Label>
              <Input
                id="title"
                name="title"
                placeholder="Lütfen blog adını giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.title}
                valid={validation.title === "T"}
                invalid={validation.title === "F"}
              />
           
            </FormGroup>
         
          </div>
        </div>

        <Label for="desc">
          <b>Blog İçeriği</b>
        </Label>
        <CKEditor
          name="desc"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.desc !== "") editor.setData(form.desc);
          }}
          onChange={(event, editor) =>
            onChangeHandler("desc", editor.getData())
          }
        />
        {validation.icerik === "F" && (
          <div className="val-message">Bu alan boş bırakılamaz.</div>
        )}

        <br />
        <FormGroup>
              <Label for="pdf">
                <b>Blog PDF Linki</b>
              </Label>
              <Input
                id="pdf"
                name="pdf"
                placeholder="Lütfen blog pdf urlini giriniz"
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.title}
                valid={validation.title === "T"}
                invalid={validation.title === "F"}
              />
           
            </FormGroup>
            <br/>
            <Button
  color="dark"
  type="submit"
  onClick={(e) => onSubmitHandler(e)}
  style={{ display: isEdit ? "none" : "inline-block" }} 
>
  Ekle
</Button>
{"  "}
<Button
  type="submit"
  style={{
    background: "white",
    color: "black",
    display: isEdit ? "inline-block" : "none", 
  }}
  onClick={(e) => onUpdateHandler(e)}
>
  Güncelle
</Button>
      </Form>
    </div>
                
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexBlog;
