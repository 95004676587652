import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardBody, Row } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "../../Slider";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
  {
    id: 2,
    dataURL: "https://picsum.photos/seed/2/600",
  },
  {
    id: 3,
    dataURL: "https://picsum.photos/seed/3/600",
  },
  {
    id: 4,
    dataURL: "https://picsum.photos/seed/4/600",
  },
  {
    id: 5,
    dataURL: "https://picsum.photos/seed/5/600",
  },
  {
    id: 6,
    dataURL: "https://picsum.photos/seed/6/600",
  },
];
const endpoint = "experience";

function FormIndexExperience() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
    exp_no: "",
    desc_seo: "",
    title: "",
    img: "",
    title_inner: "",
    title_inner_2: "",
    projects: [{ project_img: "", location: "", type: "", project_no: "" }],
  });

  const [validation, setValidation] = useState({
    exp_no: "",
    desc_seo: "",
    title: "",
    img: "",
    title_inner: "",
    title_inner_2: "",
    projects: [{ project_img: "", location: "", type: "", project_no: "" }],
  });

  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState }));
        });
      }
      setIsEdit(location.state.isEdit !== undefined ? location.state.isEdit : false);
    }
  }, []);
  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("desc_seo", form.desc_seo);
    formData.append("exp_no", form.exp_no);
    formData.append("title", form.title);
    formData.append("img", form.img);
    formData.append("title_inner", form.title_inner);
    formData.append("title_inner_2", form.title_inner_2);
    console.log(form);
    form.projects.forEach((project, index) => {
      formData.append(`project_img[${index}]`, project.project_img);
      formData.append(`projects[${index}][location]`, project.location);
      formData.append(`projects[${index}][type]`, project.type);
      formData.append(`projects[${index}][project_no]`, project.project_no);
    });
    

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "deneyim eklendi..",
        ((window.location.href = "/deneyimler"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("deneyim eklenemedi..", 2.5);
    }
  };
  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);

      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("desc_seo", form.desc_seo);
      formData.append("exp_no", form.exp_no);
      formData.append("title", form.title);
      formData.append("img", form.img);
      formData.append("title_inner", form.title_inner);
      formData.append("title_inner_2", form.title_inner_2);
      form.projects.forEach((project, index) => {
        formData.append(`project_img[${index}]`, project.project_img);
        formData.append(`projects[${index}][location]`, project.location);
        formData.append(`projects[${index}][type]`, project.type);
        formData.append(`projects[${index}][project_no]`, project.project_no);
      });
      

      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/deneyimler"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };
  const onChangeProjectHandler = (index, key, value) => {
    const updatedProjects = [...form.projects];
    if (key === "project_img") {
      // value burada file olmalı
      if (value instanceof File) {
        imageUploadProject(value, index);
      }
    } else {
      updatedProjects[index][key] = value;
      setForm((prevState) => ({ ...prevState, projects: updatedProjects }));
    }
  };
  
  const addProject = () => {
    setForm((prevState) => ({
      ...prevState,
      projects: [
        ...prevState.projects,
        { location: "", type: "", project_img: "", project_no: "" },
      ],
    }));
  };

  const removeProject = (index) => {
    const updatedProjects = [...form.projects];
    updatedProjects.splice(index, 1);
    setForm((prevState) => ({ ...prevState, projects: updatedProjects }));
  };
  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
        setValidation((prevState) => ({ ...prevState, img: "T" }));
      });
  }, []);

  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };
  const imageUploadProject = (data, index) => {
    setForm((prevState) => ({
      ...prevState,
      projects: prevState.projects.map((project, i) => {
        if (i === index) {
          return { ...project, project_img: data };
        }
        return project;
      }),
    }));
  };
  

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Deneyimler</div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <br />
                  <div className="container mt-3 pb-5">
                    <h4>Deneyim ekleme Paneli</h4>
                    <hr />

                    <Form onSubmit={onSubmitHandler}>
                      <Label>
                        <b>Ana Deneyim Fotoğrafı(Fotoğraf boyutu 216x250 boyutlarında olmalıdır! İlgili görseli seçtikten sonra mavi yükleme butonuna tıklayınız.)</b>
                      </Label>

                      <Slider imageUpload={imageUpload} data={dataSources} />
                      <br />
                      <FormGroup>
                        <Label for="exp_no">
                          <b>Deneyim Numarası</b>
                        </Label>
                        <Input
                          id="exp_no"
                          name="exp_no"
                          placeholder="Lütfen Deneyim numarası giriniz..."
                          type="number"
                          valid={validation.exp_no === "T"}
                          invalid={validation.exp_no === "F"}
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.exp_no}
                          min={0}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="title">
                          <b>Deneyim adı</b>
                        </Label>
                        <Input
                          id="title"
                          name="title"
                          placeholder="Lütfen Deneyim adını giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title}
                          valid={validation.title === "T"}
                          invalid={validation.title === "F"}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="title_inner">
                          <b>Title Inner</b>
                        </Label>
                        <Input
                          id="title_inner"
                          name="title_inner"
                          placeholder="Lütfen tittle_inner giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title_inner}
                          valid={validation.title_inner === "T"}
                          invalid={validation.title_inner === "F"}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="title_inner_2">
                          <b>Title Inner 2</b>
                        </Label>
                        <Input
                          id="title_inner_2"
                          name="title_inner_2"
                          placeholder="Lütfen tittle_inner_2 giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title_inner_2}
                          valid={validation.title_inner_2 === "T"}
                          invalid={validation.title_inner_2 === "F"}
                        />
                      </FormGroup>
                      <Label for="desc_seo">
                        <b>Açıklama</b>
                      </Label>
                      <CKEditor
                        name="desc_seo"
                        editor={ClassicEditor}
                        onReady={(editor) => {
                          if (form.desc_seo !== "")
                            editor.setData(form.desc_seo);
                        }}
                        onChange={(event, editor) =>
                          onChangeHandler("desc_seo", editor.getData())
                        }
                      />
                      <br />
                      <FormGroup>
                        <Label for="projects">Projeler</Label>
                        {form.projects.map((project, index) => (
                          <div key={index}>
                              <br />
                              <br />
                              <label>Proje Numarası</label>
                             <Input
                              type="text"
                              name="project_no"
                              id={`project_no-${index}`}
                              placeholder="Proje Numarası"
                              value={project.project_no}
                              onChange={(e) =>
                                onChangeProjectHandler(
                                  index,
                                  "project_no",
                                  e.target.value
                                )
                              }
                            />
                            <br />
                            <Label>
                              <b>Proje Fotoğrafı(Fotoğraf boyutu 670x310 boyutlarında olmalıdır! İlgili görseli seçtikten sonra mavi yükleme butonuna tıklayınız.)</b>
                            </Label>

                            <Slider
                              imageUpload={imageUploadProject}
                              data={dataSources}
                              index={index}
                            />
                            <br />
                           
                            <Input
                              type="text"
                              name="location"
                              id={`location-${index}`}
                              placeholder="Proje Konumu"
                              value={project.location}
                              onChange={(e) =>
                                onChangeProjectHandler(
                                  index,
                                  "location",
                                  e.target.value
                                )
                              }
                            />
                            <br />
                            <Input
                              type="text"
                              name="type"
                              id={`type-${index}`}
                              placeholder="Proje Türü"
                              value={project.type}
                              onChange={(e) =>
                                onChangeProjectHandler(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              }
                            />
                            <br />
                            <Button
                              color="danger"
                              onClick={() => removeProject(index)}
                            >
                              Proje Kaldır
                            </Button>
                          </div>
                        ))}
                        <br />
                        <Button color="info" onClick={addProject}>
                          Proje Ekle
                        </Button>
                      </FormGroup>
                      <div className="col-12">  <Button
                        color="dark"
                        className="mt-5 w-100"
                        type="submit"
                        onClick={(e) => onSubmitHandler(e)}
                        style={{ display: isEdit ? "none" : "inline-block" ,background:"green"}} 

                      >
                     Deneyimi Ekle
                      </Button></div>
                    
                   <div className="col-12 ">  <Button
                        type="submit"
                        className="w-100 mt-5"
                        style={{
                          background: "white",
                          color: "black",
                          display: isEdit ? "inline-block" : "none", 
                        }}
                        onClick={(e) => onUpdateHandler(e)}
                      >
                      Deneyimi  Güncelle
                      </Button></div>
                    
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexExperience;
