import React from "react";
import {
  Container,
} from "reactstrap";
import Layout from "../../Layout";
import Footer from "../../Layout/Footer";
import GesDenetimleri from "./GesDenetimleri";

const GesDenetimleriMain = () => {
  return (
    <Layout>
      <main className="home">
        <Container>
          <div className="h1">Ges Denetimleri</div>
        </Container>
        <GesDenetimleri />
        <Footer />
      </main>
    </Layout>
  );
};

export default GesDenetimleriMain;
